import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';

// remove this icon for now
// import Info from '@web/components/FanOut/components/icons/Info';
import Ticket from '@web/components/FanOut/components/icons/Ticket';
import Copy from '@web/components/FanOut/components/icons/Copy';
import ShareIcon from '@web/components/VoterRegistration/newComponents/icons/Share';
import Button from '@web/components/VoterRegistration/newComponents/Button';
import { colors } from '@web/styles/theme';
import EntriesInfo from '@web/components/FanOut/components/EntriesInfo';
import EntriesContainer from '@web/components/FanOut/components/EntriesContainer';
import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import useIsMobile from '@web/components/VoterRegistration/hooks/useIsMobile';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 740px;
  width: 100%;

  ${smallBreakpoint} {
    gap: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  border-radius: 12px;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  background: ${({ theme }) => theme.colors.white};
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const IconContainer = styled.div`
  display: flex;
  width: 68px;
  height: 68px;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 4px;
  border: 1px solid ${colors.black};
  box-shadow: 4px 4px 0px 0px ${colors.black};
`;

const BaseText = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-family: ${({ theme }) => theme.colors.regular};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
`;

const HeaderText = styled(BaseText)`
  width: 100%;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2rem;

  ${smallBreakpoint} {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
`;

// const InfoBox = styled.div`
//   width: 24px;
//   height: 24px;
// `;

const DescriptionText = styled(BaseText)`
  margin: 12px 0 16px 0;
`;

const StyledButton = styled(Button)`
  gap: 8px;
  &&& {
    height: 64px;
    background: ${({ $color, theme }) => $color || theme.colors.newBlue} !important;
  }
`;

const UnderlineButton = styled(Button)`
  text-decoration-line: underline;
`;

const StyledLink = styled(Link)`
  outline: 0;

  :hover,
  .active {
    text-decoration: none;
    text-decoration-color: unset;
  }
`;

const NotSureText = styled(BaseText)`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem; /* 120% */
  margin-bottom: 16px;
`;

const SweepstakesEntries = ({
  activity,
  openReferralModal,
  openVoterModal,
  myStats: { entries, recruits },
  statsLoading,
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { search } = useLocation();
  const { contactFormResult, isCheckedVoterRegistration } = useSelector(
    state => state.voterRegistration,
  );

  const color = useMemo(() => activity?.settings?.button_color, [activity?.settings?.button_color]);
  const showMobileShare = useMemo(() => navigator?.share, []);
  const isActive = useMemo(() => activity.aasm_state !== 'closed', [activity.aasm_state]);
  const referralLink = useMemo(
    () => contactFormResult.referral_link,
    [contactFormResult.referral_link],
  );
  const description = useMemo(() => activity?.description, [activity?.description]);

  return (
    <Wrapper>
      <Container>
        <HeaderContainer>
          <IconContainer>
            <Ticket />
          </IconContainer>
          <HeaderText dangerouslySetInnerHTML={{ __html: t('fan_out.main.title') }} />
          {/* <InfoBox>
            <Info />
          </InfoBox> */}
        </HeaderContainer>
        <DescriptionText>{t('fan_out.main.description')}</DescriptionText>
        <EntriesInfo
          statsLoading={statsLoading}
          referralLink={referralLink}
          entriesCounter={entries}
          pendingCounter={0}
          recruitsCounter={recruits}
          isCheckedVoterRegistration={isCheckedVoterRegistration}
        />
        <StyledButton onClick={openReferralModal} variant="fan_out" $color={color}>
          {referralLink ? (
            showMobileShare ? (
              <>
                <ShareIcon fill={colors.blue} />
                {t('check_registration.referral.shareSweepstake')}
              </>
            ) : (
              <>
                <Copy fill={colors.blue} />
                {t('check_registration.referral.copyLink')}
              </>
            )
          ) : (
            t('fan_out.main.enter_sweepstakes')
          )}
        </StyledButton>
        {isActive && !isCheckedVoterRegistration && (
          <UnderlineButton onClick={openVoterModal} variant="link">
            {t('fan_out.main.check_my_registration')}
          </UnderlineButton>
        )}
      </Container>
      <EntriesContainer
        entriesCounter={entries}
        description={description}
        pendingCounter={0}
        recruitsCounter={recruits}
        isCheckedVoterRegistration={isCheckedVoterRegistration}
        referralLink={referralLink}
      />
      {isMobile && (
        <Container>
          <NotSureText>{t('fan_out.main.not_sure')}</NotSureText>
          <StyledLink to={{ search, state: 'sweepstakes' }}>
            <StyledButton variant="fan_out" $color={color}>
              {t('fan_out.main.explore_all_sweepstakes')}
            </StyledButton>
          </StyledLink>
        </Container>
      )}
    </Wrapper>
  );
};

SweepstakesEntries.propTypes = {
  activity: PropTypes.object.isRequired,
  modalReferralOpen: PropTypes.bool.isRequired,
  modalVoterOpen: PropTypes.bool.isRequired,
  myStats: PropTypes.shape({
    entries: PropTypes.number,
    recruits: PropTypes.number,
  }).isRequired,
  openReferralModal: PropTypes.func.isRequired,
  openVoterModal: PropTypes.func.isRequired,
  statsLoading: PropTypes.bool.isRequired,
};

SweepstakesEntries.defaultProps = {};

export default SweepstakesEntries;

import theme from '@web/styles/theme';
import { ErrorMessage } from 'formik';
import { any, func, string } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import Autocomplete from 'react-google-autocomplete';
import parseAddress from '@web/utils/parseAddress';

const Wrapper = styled.div``;

const StyledAutocomplete = styled(Autocomplete)`
  &&& {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 10px 14px 12px;
    width: 100%;
    height: 42px;

    &:hover {
      border: 1px solid #40a9ff;
    }

    &:focus-visible {
      outline: 0;
      border-right-width: 1px !important;
      border: 1px solid #40a9ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

    svg {
      color: ${({ $outlineColor }) => $outlineColor ?? theme.colors.gray};
    }

    font-size: 14px;

    border: 1px solid ${({ $outlineColor }) => $outlineColor ?? theme.colors.gray};

    background-color: ${({ $backgroundColor }) => $backgroundColor ?? theme.colors.alabaster};
    color: ${({ $fontColor }) => $fontColor ?? theme.colors.black};

    ::placeholder {
      color: ${({ $outlineColor }) => $outlineColor ?? theme.colors.blackL72};
    }

    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-autofill:first-line {
      color: ${({ $fontColor }) => $fontColor ?? theme.colors.black};
    }

    box-shadow: 0 0 0 30px ${({ $backgroundColor }) => $backgroundColor ?? theme.colors.alabaster}
      inset !important;
    -webkit-box-shadow: 0 0 0 30px
      ${({ $backgroundColor }) => $backgroundColor ?? theme.colors.alabaster} inset !important;
  }
`;

export const Label = styled.div`
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 0.875rem;
  ${({ theme }) => css`
    color: ${({ $labelFontColor }) => $labelFontColor ?? theme.colors.black};
    font-family: ${theme.fonts.bold};
  `}
`;

export const StyledErrorMessage = styled(ErrorMessage).attrs({ component: 'div' })`
  color: #ea5156;
  font-size: 12px;
  margin-top: 4px;
`;

const AddressFormField = ({ name, value, label, placeholder, inputRef, onChange, ...rest }) => {
  const selectPlace = useCallback(
    place => {
      const { street_name, street_number, ...parsedAddress } = parseAddress(place);
      onChange(parsedAddress);
    },
    [onChange],
  );

  const handleValueChange = useCallback(
    event => {
      if (event.target.value === '') {
        onChange({
          address: '',
          city: '',
          county_name: '',
          state_abbrev: '',
          zip_code: '',
        });
      }
    },
    [onChange],
  );

  const options = useMemo(
    () => ({
      componentRestrictions: { country: 'us' },
      types: ['address'],
    }),
    [],
  );

  return (
    <Wrapper>
      <Label $labelFontColor={rest.labelFontColor}>{label}</Label>
      <StyledAutocomplete
        autoComplete="off"
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
        defaultValue=""
        id={name}
        name={name}
        onChange={handleValueChange}
        $fontColor={rest.fontColor}
        $backgroundColor={rest.backgroundColor}
        $outlineColor={rest.outlineColor}
        onPlaceSelected={selectPlace}
        options={options}
        placeholder={placeholder ? placeholder : label}
        type="search"
        ref={inputRef}
        {...rest}
      />
      <StyledErrorMessage data-testid={`${name}-error`} name={name} />
    </Wrapper>
  );
};

AddressFormField.propTypes = {
  backgroundColor: string,
  fontColor: string,
  inputRef: any,
  label: string,
  labelFontColor: string,
  name: string.isRequired,
  onChange: func,
  outlineColor: string,
  placeholder: string,
  value: string,
};

export default AddressFormField;

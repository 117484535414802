import I18n from '@web/components/i18n';

export const isBattleGroundState = state => {
  const battlegroundStates = ['NV', 'AZ', 'GA', 'WI', 'PA', 'MI', 'NC', 'OH', 'MT'];

  return battlegroundStates.includes(state);
};

export const getEntriesArray = isCheckedVoterRegistration => {
  return [
    {
      checked: false,
      description: I18n.t('fan_out.main.entries.1_description'),
      title: I18n.t('fan_out.main.entries.entry', {
        count: 10,
      }),
    },
    {
      checked: false,
      description: I18n.t('fan_out.main.entries.2_description'),
      title: I18n.t('fan_out.main.entries.entry', {
        count: 1,
      }),
    },
    {
      checked: false,
      description: I18n.t('fan_out.main.entries.3_description'),
      title: I18n.t('fan_out.main.entries.additional', {
        count: 2,
      }),
    },
    {
      checked: isCheckedVoterRegistration,
      description: I18n.t('fan_out.main.entries.4_description'),
      title: I18n.t('fan_out.main.entries.entry', {
        count: 1,
      }),
    },
    {
      checked: false,
      description: I18n.t('fan_out.main.entries.5_description'),
      title: I18n.t('fan_out.main.entries.entry', {
        count: 1,
      }),
    },
  ];
};

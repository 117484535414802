import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import BackButton from '@web/components/VoterRegistration/newComponents/Button/BackButton';
import Button from '@web/components/VoterRegistration/newComponents/Button';
import { Description, Title } from '@web/components/VoterRegistration/newComponents/TextStyles';
import NoAddressMatch from './NoAddressMatch';
import { checkRegistrationReviewInfo } from '@web/components/VoterRegistration/hooks/useFlows';
import { setSelectedVoter } from '@web/reducers/voterRegistration';
import { largeBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import parseVoterObject from '@web/components/VoterRegistration/helpers/parseVoterObject';
import { track } from '@web/services/analytics';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { vrDefaultColors } from '@web/styles/theme';

const ButtonRow = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 1rem;

  ${largeBreakpoint} {
    flex-direction: row;
    justify-content: space-between;
    button {
      max-width: 250px;
    }
  }
`;

const Push = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StyledAddressConfirmation = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledBackButton = styled(BackButton)`
  text-transform: ${({ isFanOut }) => (isFanOut ? 'capitalize' : 'none')};
`;

const AddressConfirmation = ({
  activity,
  nextPage,
  previousPage,
  setSelectedFlow,
  team,
  isFanOut,
  ...rest
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [pickedAddress, setPickedAddress] = React.useState('');
  const { contactFormResult, selectedVoter } = useSelector(state => state.voterRegistration);
  const differentAddress = selectedVoter?.registration_status === 'Different Address';
  const emptyVoter = !selectedVoter || (selectedVoter && Object.keys(selectedVoter).length === 0);
  const parsedVoter = parseVoterObject(selectedVoter?.voterfile);

  React.useEffect(() => {
    if (!isFanOut) {
      track('VR_VIEW_CHECK_REGISTRATION_ADDRESS_CONFIRMATION', {
        activity_id: activity?.id,
        activity_title: activity?.title,
        activity_type: activity?.type,
        campaign_id: activity?.campaign?.id,
        campaign_name: activity?.campaign?.name,
        team_id: team?.id,
        team_name: team?.name,
      });
    }
    // eslint-disable-next-line
  }, []);

  const recordAddress = React.useMemo(
    () => `${parsedVoter?.addressLine1} ${parsedVoter?.addressLine2}, ${parsedVoter?.zip}`,
    [parsedVoter],
  );
  const userAddress = React.useMemo(
    () =>
      `${contactFormResult?.address} ${contactFormResult?.city}, ${contactFormResult?.state_abbrev} ${contactFormResult?.zip_code}`,
    [contactFormResult],
  );

  const handleUserCorrect = React.useCallback(() => {
    if (loading) return;
    if (!isFanOut) {
      setSelectedFlow(checkRegistrationReviewInfo);
      dispatch(setSelectedVoter(null));
      nextPage();
      return;
    } else {
      dispatch(setSelectedVoter(null));
      nextPage();
    }
  }, [dispatch, isFanOut, loading, nextPage, setSelectedFlow]);

  const handleRecordCorrect = React.useCallback(() => {
    if (loading) return;
    setLoading(true);
    axios
      .patch(`/api/v2/client/voter_registrations/${contactFormResult?.id}/set_voter`, {
        activity_id: activity.id,
        campaign_id: activity.campaign.id,
        h: contactFormResult?.encode_id,
        team_id: team?.id,
        voter_id: parsedVoter.voterbaseId,
      })
      .then(({ data }) => {
        dispatch(setSelectedVoter(parsedVoter));
        nextPage();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [activity, contactFormResult, dispatch, nextPage, parsedVoter, team, loading]);

  const handleSelect = React.useCallback(() => {
    if (pickedAddress === 'record') {
      handleRecordCorrect();
    } else if (pickedAddress === 'user') {
      handleUserCorrect();
    }
  }, [handleRecordCorrect, handleUserCorrect, pickedAddress]);

  const handleBack = React.useCallback(() => {
    if (isFanOut) {
      dispatch(setSelectedVoter(null));
    }
    previousPage();
  }, [dispatch, isFanOut, previousPage]);

  const titleText = React.useMemo(
    () =>
      isFanOut
        ? t('check_registration.addressConfirmation.titleFanOut')
        : t('check_registration.addressConfirmation.title'),
    [isFanOut, t],
  );

  const fontColor = React.useMemo(
    () => (isFanOut ? undefined : activity?.settings?.font_color ?? vrDefaultColors.fontColor),
    [activity?.settings?.font_color, isFanOut],
  );

  const bgColor = React.useMemo(
    () =>
      isFanOut
        ? undefined
        : activity?.settings?.background_color ?? vrDefaultColors.backgroundColor,
    [activity?.settings?.background_color, isFanOut],
  );

  const progressColor = React.useMemo(
    () =>
      isFanOut
        ? undefined
        : activity?.settings?.progress_bar_color ?? vrDefaultColors.progressBarColor,
    [activity?.settings?.progress_bar_color, isFanOut],
  );

  const addressProps = {
    activity,
    contactFormResult,
    handleRecordCorrect,
    handleUserCorrect,
    isFanOut,
    loading,
    nextPage,
    parsedVoter,
    pickedAddress,
    previousPage,
    recordAddress,
    selectedVoter,
    setPickedAddress,
    setSelectedFlow,
    team,
    userAddress,
    ...rest,
  };

  if (emptyVoter) {
    return null;
  }

  // MTS - 7/27/22 We did have a specific screen for matching addresses but
  // now we go right to the last screen if the addresses match.
  if (!differentAddress) {
    nextPage();
    return null;
  }

  return (
    <StyledAddressConfirmation>
      <Title $fontColor={fontColor}>{titleText}</Title>
      <Description $fontColor={fontColor}>
        {t('check_registration.addressConfirmation.description')}
      </Description>
      <NoAddressMatch
        progressColor={progressColor}
        bgColor={bgColor}
        fontColor={fontColor}
        {...addressProps}
      />
      <Push />
      <ButtonRow>
        <StyledBackButton
          customVariant={activity.settings}
          isFanOut={isFanOut}
          onClick={handleBack}
        >
          {t('check_registration.addressConfirmation.backButton')}
        </StyledBackButton>
        {isFanOut && (
          <Button
            onClick={handleSelect}
            disabled={!pickedAddress}
            isFanOut={isFanOut}
            variant={isFanOut ? 'fan_out' : 'blue'}
          >
            {t('check_registration.addressConfirmation.proceed')}
          </Button>
        )}
      </ButtonRow>
    </StyledAddressConfirmation>
  );
};

AddressConfirmation.propTypes = {
  activity: PropTypes.object.isRequired,
  isFanOut: PropTypes.bool,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  setSelectedFlow: PropTypes.func.isRequired,
  team: PropTypes.object,
};

AddressConfirmation.defaultProps = {
  team: null,
};

export default AddressConfirmation;

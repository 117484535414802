import { sortByDisplayOrderFn } from '../../utils/other';

const getOtherUser = (userId, users) => {
  const not_me = users.filter(user => user.id !== userId);
  if (not_me.length > 0) {
    return not_me[0];
  }

  return {};
};

const getPersonFromMessageThread = (messageThread, userId) => {
  const { kind, contact = {}, users } = messageThread;
  if (kind === 'user_to_user') {
    return getOtherUser(userId, users);
  }
  return contact;
};

const getFullName = (firstName, lastName) => {
  const [first, last] = [firstName || '', lastName || ''];
  const maybeSpace = first && last ? ' ' : '';

  return first || last ? `${first}${maybeSpace}${last}` : 'No Name';
};

const formatCustomizationResponse = customizations => {
  return customizations
    .filter(customization => customization.custom_field.is_user_reportable !== false)
    .map(customization => {
      const {
        id,
        custom_field_id,
        custom_field: { available_values, display_order, name, prompt, slug, type },
        value,
      } = customization;
      return {
        available_values,
        custom_field_id,
        display_order,
        id,
        name,
        prompt,
        slug,
        type,
        value,
      };
    })
    .sort(sortByDisplayOrderFn);
};

const formatTaggingsResponse = taggings => {
  return taggings
    .map(tagging => {
      const {
        id,
        tag_id,
        tag: { slug, name, display_order, prompt },
        value,
      } = tagging;
      return { display_order, id, name, prompt, slug, tag_id, value };
    })
    .sort(sortByDisplayOrderFn);
};

const formatUserReportBody = (reportId, customizations, taggings) => {
  return {
    customizations_attributes: customizations.map(cust => {
      const { custom_field_id, id, value } = cust;
      return { custom_field_id, id, value };
    }),
    id: reportId,
    taggings_attributes: taggings.map(tagging => {
      const { id, tag_id, value } = tagging;
      return { id, tag_id, value };
    }),
  };
};

const getRelationshipLabel = (kind, is_staff, is_outvote, user, t) => {
  switch (kind) {
    case 'friend_to_friend':
      return t('inbox.friend_label');
    case 'user_to_user':
      // if someone in the message thread is a campaign admin, and it's not me, I'm talking to staff
      if (is_staff && !user?.is_campaign_admin) {
        return t('inbox.staff_label');
      }
      // if no one on the message thread is staff, I'm talking to a volunteer
      if (!is_staff) {
        return t('inbox.volunteer_label');
      }
      // if someonee in the message thread is from outvote/impactive and it's not me, I'm talking
      // to an Impactive Admin
      if (is_outvote && !user?.is_impactive_admin) {
        return t('inbox.impactive_admin_label');
      }
    // Falls through for if user_to_user doesn't match any clauses too
    default:
      return null;
  }
};

const getThreadTypeName = (threadType, t) => {
  switch (threadType) {
    case 'peer_to_peer':
      return t('inbox.threadType.peerToPeer');
    case 'friend_to_friend':
      return t('inbox.threadType.friendToFriend');
    case 'user_to_user':
      return t('inbox.threadType.userToUser');
    case 'team':
      return t('inbox.threadType.team');
    case 'broadcast':
      return t('inbox.threadType.broadcast');
    case 'canvass':
      return t('inbox.threadType.canvass');
    default:
      return '';
  }
};

export {
  getOtherUser,
  getPersonFromMessageThread,
  getFullName,
  formatCustomizationResponse,
  formatTaggingsResponse,
  formatUserReportBody,
  getRelationshipLabel,
  getThreadTypeName,
};

import dayjs from 'dayjs';

import I18n from '@web/components/i18n';
import { capitalize } from '@web/utils/string';

const districtParser = (voter, district) => {
  return {
    // localization
    title: I18n.t(`idvoters.voter.district.${district}`),
    // return '' if no voter
    value: !voter
      ? ''
      : // return value if city council
      district === 'city_council'
      ? voter?.[`vb.vf_${district}`]
      : // return value if not city council
        voter?.[`vb.tsmart_${district}`],
  };
};

export const getDistrictInfo = voter => [
  districtParser(voter, 'cd'),
  districtParser(voter, 'sd'),
  districtParser(voter, 'hd'),
  districtParser(voter, 'city_council'),
];

const getFirstElectionYear = voter => {
  const dob = voter?.['vb.voterbase_dob'] || '';

  if (!dob) return 0;

  const birthYear = parseInt(dob.slice(0, 4), 10);
  const firstElectionYear = birthYear + 19;

  return firstElectionYear;
};

const generalParser = (voter, year) => {
  const firstElectionYear = getFirstElectionYear(voter);

  return {
    // localization
    title: `${year} ${I18n.t('idvoters.voter.match.general')}`,
    // return value if voted
    value: voter?.[`vb.vf_g${year}`]
      ? voter?.[`vb.vf_g${year}`]
      : //if not old enough return na
      firstElectionYear > year
      ? 'na'
      : //if didn't vote return miss
        'miss',
  };
};

const primaryParser = (voter, year) => {
  const firstElectionYear = getFirstElectionYear(voter);

  return {
    // localization
    title: `${year} ${I18n.t('idvoters.voter.match.primary')}`,
    // return value if voted
    value: voter?.[`vb.vf_p${year}`]
      ? voter?.[`vb.vf_p${year}`]
      : //if not old enough return na
      firstElectionYear > year
      ? 'na'
      : // if didn't vote return miss
        'miss',
  };
};

export const getVotingRecords = voter => [
  generalParser(voter, 2022),
  primaryParser(voter, 2022),
  generalParser(voter, 2020),
  primaryParser(voter, 2020),
  generalParser(voter, 2018),
  primaryParser(voter, 2018),
  generalParser(voter, 2016),
  primaryParser(voter, 2016),
  generalParser(voter, 2014),
  primaryParser(voter, 2014),
  generalParser(voter, 2012),
  primaryParser(voter, 2012),
  generalParser(voter, 2010),
  primaryParser(voter, 2010),
  generalParser(voter, 2008),
  primaryParser(voter, 2008),
];

export const getGeneralVotingRecords = voter => [
  generalParser(voter, 2022),
  generalParser(voter, 2020),
  generalParser(voter, 2018),
  generalParser(voter, 2016),
  generalParser(voter, 2014),
  generalParser(voter, 2012),
  generalParser(voter, 2010),
  generalParser(voter, 2008),
];

export const getPrimaryVotingRecords = voter => [
  primaryParser(voter, 2022),
  primaryParser(voter, 2020),
  primaryParser(voter, 2018),
  primaryParser(voter, 2016),
  primaryParser(voter, 2014),
  primaryParser(voter, 2012),
  primaryParser(voter, 2010),
  primaryParser(voter, 2008),
];

export const getFrequency = voter => {
  let frequency = I18n.t('idvoters.voter.tags.infrequent');
  let frequencyPercentage = 0;

  if (voter) {
    const votingRecords = getVotingRecords(voter);

    const total = votingRecords.length;
    const misses = votingRecords.filter(item => item.value === 'miss').length || 0;
    const ineligible = votingRecords.filter(item => item.value === 'na').length || 0;
    const eligible = total - ineligible;
    const hits = total - misses - ineligible;

    frequencyPercentage = hits / eligible;

    if (frequencyPercentage > 0.49) {
      frequency = I18n.t('idvoters.voter.tags.frequent');
    }

    if (frequencyPercentage > 0.69) {
      frequency = I18n.t('idvoters.voter.tags.super');
    }
  }

  return { frequency, frequencyPercentage };
};

export const frequencyTagConverter = frequency => {
  switch (frequency) {
    case I18n.t('idvoters.voter.tags.infrequent'):
      return 'infrequent';
    case I18n.t('idvoters.voter.tags.frequent'):
      return 'frequent';
    case I18n.t('idvoters.voter.tags.super'):
      return 'super';
    case I18n.t('idvoters.voter.tags.unregistered'):
      return 'infrequent';
    case I18n.t('idvoters.voter.tags.never_voted'):
      return 'infrequent';
    default:
      return undefined;
  }
};

export const getInfo = (contactFormResult, selectedVoter) => {
  if (selectedVoter && Object.keys(selectedVoter).length !== 0) {
    return {
      address: `${selectedVoter.addressLine1} ${selectedVoter.addressLine2}`,
      age: selectedVoter.age || '',
      name: capitalize(selectedVoter.name),
      state: selectedVoter.state,
      zip: selectedVoter.zip,
    };
  }

  const address = [
    contactFormResult.address,
    contactFormResult.city,
    contactFormResult.state_abbrev,
    contactFormResult.zip_code,
  ]
    .filter(item => !!item)
    .join(', ');

  return {
    address,
    age:
      Math.abs(dayjs(contactFormResult.date_of_birth, 'MM/DD/YYYY').diff(dayjs(), 'years')) || '',
    name: capitalize(`${contactFormResult.first_name} ${contactFormResult.last_name}`),
    state: contactFormResult.state_abbrev,
    zip: contactFormResult.zip_code,
  };
};

export const getParty = voter => {
  const party = voter?.['vb.vf_party'] || '';

  switch (party) {
    case 'Democrat':
      return {
        icon: 'democrat',
        party: I18n.t('idvoters.voter.tags.democrat'),
      };
    case 'Republican':
      return {
        icon: 'republican',
        party: I18n.t('idvoters.voter.tags.republican'),
      };

    default:
      return {
        icon: '',
        party: '',
      };
  }
};

export const getVoterInfo = voter => {
  const firstName = voter?.['vb.tsmart_first_name'] || '';
  const secondName = voter?.['vb.tsmart_last_name'] || '';
  const fullName = [firstName, secondName].filter(Boolean).join(' ');
  const city = voter?.['vb.tsmart_city'] || '';
  const state = voter?.['vb.tsmart_state'] || '';
  const address = [city, state].filter(Boolean).join(', ');

  return {
    address,
    city,
    firstName,
    fullName,
    secondName,
    state,
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Diamond from '@web/components/FanOut/components/icons/Diamond';
import Checked from '@web/components/FanOut/components/icons/Checked';
import { getEntriesArray } from '@web/components/FanOut/helpers';

const Container = styled.div`
  display: flex;
  border-radius: 12px;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  background: ${({ theme }) => theme.colors.white};
`;

const EntriesContent = styled.div`
  display: flex;
  align-self: stretch;
  gap: 5px;
  justify-content: flex-start;
`;

const BaseText = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-family: ${({ theme }) => theme.colors.regular};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
`;

const DescriptionText = styled(BaseText)`
  margin-bottom: 18px;
  line-height: 1.25rem;
`;

const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: flex-start;
  padding-top: 3px;
`;

const DescriptionEntriesText = styled(BaseText)`
  margin-top: 18px;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  opacity: 0.7;
`;

const EntriesTitle = styled(BaseText)`
  font-weight: 700;
  line-height: 1.25rem;
  text-align: left;
  opacity: ${({ checked }) => (checked ? 0.4 : 1)};
`;

const EntriesDescription = styled.span`
  color: ${({ theme }) => theme.colors.blue};
  font-family: ${({ theme }) => theme.colors.regular};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;

  bold {
    font-weight: 700;
  }
`;

const EntriesContainer = ({
  description,
  referralLink,
  isCheckedVoterRegistration,
  entriesCounter,
  recruitsCounter,
}) => {
  const { t } = useTranslation();

  const ENTRIES_ARRAY = getEntriesArray(isCheckedVoterRegistration);

  return (
    <Container>
      <DescriptionText>{description}</DescriptionText>
      {ENTRIES_ARRAY.map(({ title, description, checked }) => (
        <EntriesContent key={`${title}-${description}`}>
          <IconWrapper>{checked ? <Checked /> : <Diamond />}</IconWrapper>
          <EntriesTitle checked={checked}>
            {title}
            &nbsp;
            <EntriesDescription dangerouslySetInnerHTML={{ __html: description }} />
          </EntriesTitle>
          &nbsp;
        </EntriesContent>
      ))}
      <DescriptionEntriesText>{t('fan_out.main.entry_description')}</DescriptionEntriesText>
    </Container>
  );
};

EntriesContainer.propTypes = {
  description: PropTypes.string,
  entriesCounter: PropTypes.number,
  isCheckedVoterRegistration: PropTypes.bool.isRequired,
  pendingCounter: PropTypes.number,
  recruitsCounter: PropTypes.number,
  referralLink: PropTypes.string,
};

EntriesContainer.defaultProps = {};

export default EntriesContainer;

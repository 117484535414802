import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { Collapse } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import Button from '../Button';
import CircleCheck from '../icons/CircleCheck';
import CircleX from '../icons/CircleX';
import MessageBox from '../MessageBox';
import Timeline from './Timeline';
import { smallBreakpoint } from '../breakpoints';
import { getInfo } from '@web/utils/getVoterInfo';
import { getNoOnlineRegistrationAvailableDeadlineDate } from './Timeline/utils';
import theme, { vrDefaultColors } from '@web/styles/theme';
import { amplitudeTrack } from '@web/components/FanOut/hooks/useFanOutAmplitude';
import { activities } from '@web/services/api';

const DATE_FORMAT = 'MM-DD-YY';
dayjs.extend(customParseFormat);

const { Panel } = Collapse;

const CollapseAntdStyles = createGlobalStyle`
    &&& {
    .ant-collapse-content {
      background: ${({ theme }) => theme.colors.transparent}!important;
      border-top: 0px !important;
      padding: 0px !important;
    }
    .ant-collapse-header {
      padding: 0px !important;
    }
    .ant-collapse-content-box {
      padding: 0px !important;
    }
  }
`;

const StyledCollapse = styled(Collapse)`
  &&& {
    margin: 16px 0;
    border-radius: 0.5rem;
    border: 1px solid
      ${({ $isOpen, theme, $bgColor }) => (!$isOpen ? theme.colors.transparent : $bgColor)};
    overflow: hidden;
  }
`;

const StyledHeader = styled(MessageBox)`
  border-radius: 0;
  background: ${({ $bgColor }) => `${$bgColor}1A`};

  display: flex;
  flex-direction: column;
`;

const StatusBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
`;

const IconBox = styled(StatusBox)`
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;

const TitleBox = styled(StatusBox)`
  flex-direction: column;
  gap: 0;
`;

const HeaderBox = styled(StatusBox)`
  flex-wrap: wrap;
  align-items: center;
`;

const DetailsText = styled.div`
  color: ${({ theme, $fontColor }) => $fontColor ?? theme.colors.black};
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
  text-decoration-line: underline;
`;

const TextBox = styled.div`
  display: flex;
  padding: 0 16px 16px 60px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: ${({ $bgColor }) => `${$bgColor}1A`};
  white-space: pre-line;
`;

const StyledPanel = styled(Panel)`
  padding: 0;
  border: none;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0.25rem;
  margin-top: 3px;
  color: ${({ $fontColor }) => $fontColor};
`;

const Text = styled.p`
  margin-bottom: 0;
  color: ${({ $fontColor }) => $fontColor};
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-start;

  ${smallBreakpoint} {
    margin-left: 42px;
  }
`;

const TimelineBox = styled.div`
  padding: 7px 3px 0 7px;
`;

function Message({
  isNorthDakota,
  registered,
  disableCollapse,
  contact: contactFromProps,
  isFanOut,
  settings,
  activity,
  team,
}) {
  const [activeKey, setActiveKey] = React.useState(['1']);
  const { t } = useTranslation();
  const { search } = useLocation();
  const { referral_hash: referralHash } = queryString.parse(search);
  const { contactFormResult, selectedVoter } = useSelector(state => state.voterRegistration);
  const contact = contactFromProps || contactFormResult;
  const votingInfo = contact?.voting_info || contactFormResult.voting_info;
  const sameDayReg = votingInfo?.same_day_registration?.toLowerCase() === 'yes';
  // This variable is used to designate a voter who lives in a state where same-day registration
  // is allowed and who is not yet registered to vote.
  const sameDayNonReg = sameDayReg && !registered;
  const today = dayjs();
  // MTS - Leaving this here for debugging purposes
  // const today = dayjs('10-25-22', DATE_FORMAT);
  const afterGenRegDeadline = today.isAfter(dayjs(votingInfo.online_reg_deadline, DATE_FORMAT));

  function getDeadline() {
    const displayFormat = 'MMM D';
    if (today.isBefore(dayjs(votingInfo.online_primary_reg_deadline, DATE_FORMAT))) {
      return dayjs(votingInfo.online_primary_reg_deadline, DATE_FORMAT).format(displayFormat);
    }
    return dayjs(votingInfo.online_reg_deadline, DATE_FORMAT).format(displayFormat);
  }

  function getTitle() {
    if (isNorthDakota) {
      return t('voting_information.registered_nd');
    }
    return registered ? t('voting_information.registered') : t('voting_information.unregistered');
  }

  function getNotRegisteredText(state) {
    const onlinePrimaryRegDate = votingInfo.online_primary_reg_deadline;
    const onlineGeneralRegDate = votingInfo.online_reg_deadline;

    if (!onlineGeneralRegDate && !onlinePrimaryRegDate) {
      return t('voting_information.onlineRegistrationIsNotAvailableMessage', {
        date: getNoOnlineRegistrationAvailableDeadlineDate(votingInfo),
        state: t(`states.${info.state?.toUpperCase()}`),
      });
    }

    if (sameDayNonReg && afterGenRegDeadline) {
      return t('voting_information.sameDayReg', {
        state: t(`states.${state?.toUpperCase()}`),
      });
    }
    return t('voting_information.notRegisteredMessage', {
      date: getDeadline(),
      state: t(`states.${info.state?.toUpperCase()}`),
    });
  }

  const getButtonText = React.useCallback(() => {
    if (sameDayNonReg && afterGenRegDeadline) {
      return t('button.learn_more');
    }
    return t('voting_information.register_to_vote');
  }, [sameDayNonReg, afterGenRegDeadline, t]);

  const getButtonLink = React.useCallback(() => {
    if (sameDayNonReg && afterGenRegDeadline) {
      return votingInfo.source_link1;
    }

    if (votingInfo.online_reg_link) {
      return votingInfo.online_reg_link;
    }

    if (votingInfo.accepts_national_reg_form) {
      return votingInfo.national_reg_link;
    }

    if (!votingInfo.accepts_national_reg_form && votingInfo.paper_reg_link) {
      return votingInfo.paper_reg_link;
    }

    return votingInfo.source_link1;
  }, [afterGenRegDeadline, sameDayNonReg, votingInfo]);

  const info = getInfo(contact, selectedVoter);
  const title = getTitle();

  const registeredText = (
    <>
      {t('voting_information.who')} {info.name}, {info.age}{' '}
      {t('check_registration.search_results.years_old')}
      <br />
      {t('voting_information.at')} {info.address}
    </>
  );

  const notRegisteredText = getNotRegisteredText(info.state);

  const onChange = React.useCallback(
    keys => !disableCollapse && setActiveKey(keys),
    [disableCollapse],
  );

  const buttonColor = React.useMemo(
    () => settings?.button_color ?? vrDefaultColors.buttonColor,
    [settings?.button_color],
  );
  const bgColor = React.useMemo(
    () => settings?.background_color ?? vrDefaultColors.backgroundColor,
    [settings?.background_color],
  );

  const trackOfLinkClick = React.useCallback(
    url => {
      activities.trackExternalLinkClick({
        activityId: activity?.id,
        activitySlug: activity?.slug,
        campaignId: activity?.campaign?.id,
        contactId: contactFormResult?.id,
        url,
      });
    },
    [activity?.campaign?.id, activity?.id, activity?.slug, contactFormResult?.id],
  );

  const onRegisterClick = React.useCallback(
    e => {
      if (isFanOut) {
        amplitudeTrack({
          activity,
          name: referralHash
            ? 'FAN_OUT_REGISTER_TO_VOTE_CLICK_REFERRED'
            : 'FAN_OUT_REGISTER_TO_VOTE_CLICK',
          team,
        });
      }
      trackOfLinkClick(getButtonLink());
      e.stopPropagation();
    },
    [isFanOut, activity, team, referralHash, getButtonLink, trackOfLinkClick],
  );

  const RegisterButton = React.useMemo(
    () =>
      !registered && (
        <ButtonArea $isOpen={activeKey?.length}>
          <Button
            forwardedAs="a"
            href={getButtonLink()}
            rel="noopener noreferrer"
            style={
              ({
                display: 'inline-flex',
                minWidth: 180,
                width: 'initial',
              },
              isFanOut
                ? {
                    background: 'white',
                    color: theme.colors.blue,
                    textTransform: 'capitalize',
                  }
                : { background: buttonColor, borderColor: buttonColor, color: bgColor })
            }
            target="_blank"
            onClick={onRegisterClick}
          >
            {getButtonText()}
          </Button>
        </ButtonArea>
      ),
    [
      getButtonText,
      registered,
      getButtonLink,
      activeKey,
      isFanOut,
      onRegisterClick,
      buttonColor,
      bgColor,
    ],
  );

  const fontColor = React.useMemo(
    () => settings?.font_color ?? vrDefaultColors.fontColor,
    [settings?.font_color],
  );

  const statusColor = React.useMemo(() => {
    if (registered) {
      return settings?.registered_color ?? vrDefaultColors.registeredColor;
    }
    return settings?.unregistered_color ?? vrDefaultColors.unRegisteredColor;
  }, [registered, settings?.registered_color, settings?.unregistered_color]);

  const DetailsButton = React.useMemo(
    () => (
      <DetailsText $fontColor={fontColor}>
        {activeKey?.length
          ? t('voting_information.hide_details')
          : t('voting_information.view_details')}
      </DetailsText>
    ),
    [activeKey, t, fontColor],
  );

  return (
    <>
      <CollapseAntdStyles />
      <StyledCollapse
        $bgColor={statusColor}
        $registered={registered}
        $isOpen={activeKey?.length}
        onChange={onChange}
        expandIconPosition={'right'}
        activeKey={activeKey}
        ghost
      >
        <StyledPanel
          header={
            <StyledHeader
              $registered={registered}
              $bgColor={statusColor}
              $isOpen={activeKey?.length}
            >
              <IconBox>
                <StatusBox>
                  <HeaderBox>
                    <IconBox>
                      {registered ? <CircleCheck /> : <CircleX />}
                      <TitleBox
                        style={{
                          display: 'flex',
                          flex: 1,
                          flexDirection: 'column',
                        }}
                      >
                        <Title $fontColor={fontColor}>{title}</Title>
                        {!disableCollapse && !registered && DetailsButton}
                      </TitleBox>
                    </IconBox>
                    {RegisterButton}
                  </HeaderBox>
                </StatusBox>
                {!disableCollapse && registered && DetailsButton}
              </IconBox>
            </StyledHeader>
          }
          key={'1'}
          showArrow={false}
        >
          <>
            <TextBox $bgColor={statusColor} $registered={registered}>
              {registered ? (
                <Text $fontColor={fontColor}>{registeredText}</Text>
              ) : (
                <Text
                  $fontColor={fontColor}
                  dangerouslySetInnerHTML={{
                    __html: notRegisteredText,
                  }}
                />
              )}
            </TextBox>
            <TimelineBox>
              <Timeline
                statusColor={statusColor}
                contact={contact}
                fontColor={fontColor}
                registered={registered}
                votingInfo={votingInfo}
              />
            </TimelineBox>
          </>
        </StyledPanel>
      </StyledCollapse>
    </>
  );
}

Message.propTypes = {
  activity: PropTypes.object.isRequired,
  contact: PropTypes.object,
  disableCollapse: PropTypes.bool,
  isFanOut: PropTypes.bool,
  isNorthDakota: PropTypes.bool,
  registered: PropTypes.bool.isRequired,
  settings: PropTypes.object,
  team: PropTypes.object,
};

Message.defaultProps = {
  isNorthDakota: false,
};

export default Message;

import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Card } from '@web/components/common/shared/Card';
import theme from '@web/styles/theme';
import { media } from '@web/styles/theme';
import ShareButtons from '@web/components/SurveyActivity/components/ShareButtons';
import { getFont } from './utils';

const StyledCard = styled(Card)`
  margin: 35px auto;
  padding: 70px 45px;
  width: 740px;

  background: ${({ $backgroundColor }) =>
    $backgroundColor ?? theme.formDefaultColors.sectionBackgroundColor};

  ${media.nlg} {
    width: calc(100% - 32px);
    padding: 70px 10px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  svg {
    color: ${({ $fontColor }) => $fontColor ?? theme.formDefaultColors.fontColor};
  }

  * {
    font-family: ${({ $font }) => getFont($font)};
  }
`;

const Header = styled.h2`
  margin: 0 0 8px;
  padding: 0;

  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;

  color: ${({ $fontColor }) => $fontColor ?? theme.formDefaultColors.fontColor};
`;

const Image = styled.img`
  width: 100%;
  object-fit: contain;

  @media screen and (max-width: 768px) {
    height: unset;
  }
`;

const StyledMessage = styled.p`
  margin: 1rem;
  color: ${({ $fontColor }) => $fontColor ?? theme.formDefaultColors.fontColor};
  text-align: center;
  white-space: pre-wrap;
`;

const ReferralLink = ({ incentive, link, settings, speechifyAiData, contactId }) => {
  const { t } = useTranslation();

  // Eliminates CLS (Cumulative Layout Shift) by setting the image height properly
  const imgHeight = incentive?.name ? 240 : 130;

  const titleText = settings.thank_you_title || t('survey.thank-you-default-title');
  let messageText = settings.thank_you_message || t('survey.thank-you-default-message');

  if (settings.allow_sharing && !settings.thank_you_message) {
    messageText += ` ${t('survey.thank-you-share-message')}`;
  }

  const iFrameUrl = `${speechifyAiData?.speechifyai_url}?&url=${encodeURIComponent(
    link,
  )}&v=${contactId}`;

  const { section_background_color, main_font_color, font } = settings ?? {};

  return (
    <StyledCard
      $font={font}
      $backgroundColor={section_background_color}
      $fontColor={main_font_color}
    >
      {incentive?.name && <Image src={incentive?.name} alt="incentive" height={imgHeight} />}
      <div>
        <Header $fontColor={main_font_color}>{titleText}</Header>
        <StyledMessage $fontColor={main_font_color}>{messageText}</StyledMessage>
      </div>
      {speechifyAiData ? (
        <iframe
          title="SpeechifyAi Frame"
          id="SpeechifyAiFrame"
          src={iFrameUrl}
          width="100%"
          height="550px"
          style={{ border: 'none' }}
        />
      ) : (
        settings.allow_sharing && <ShareButtons link={link} settings={settings} />
      )}
    </StyledCard>
  );
};

ReferralLink.propTypes = {
  contactId: PropTypes.number,
  incentive: PropTypes.shape({
    name: PropTypes.string,
  }),
  link: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    allow_sharing: PropTypes.bool,
    font: PropTypes.string,
    main_font_color: PropTypes.string,
    section_background_color: PropTypes.string,
    thank_you_message: PropTypes.string,
    thank_you_title: PropTypes.string,
  }),
  speechifyAiData: PropTypes.shape({
    activity_id: PropTypes.number,
    speechifyai_url: PropTypes.string,
  }),
};

export default ReferralLink;

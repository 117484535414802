import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';

import Entries from '../icons/Entries';
import Recruits from '../icons/Recruits';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  margin-bottom: 12px;
`;

const Box = styled.div`
  display: flex;
  height: 64px;
  padding: 8px 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex: 1;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const Counter = styled.div`
  opacity: ${({ $fullColor }) => ($fullColor ? 1 : 0.2)};
  color: #393e5b;
  width: 24px;
  align-items: flex-start;
  /* shadow-m */
  text-shadow: 1px 3px 12px rgba(0, 0, 0, 0.08);
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.875rem;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.colors.blackL42};
  flex: 1;
  /* shadow-m */
  text-shadow: 1px 3px 12px rgba(0, 0, 0, 0.08);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
`;

const EntriesInfo = ({
  referralLink,
  isCheckedVoterRegistration,
  entriesCounter,
  pendingCounter,
  recruitsCounter,
  statsLoading,
}) => {
  const { t } = useTranslation();

  const fullEntriesColor = useMemo(
    () => isCheckedVoterRegistration && !!entriesCounter,
    [entriesCounter, isCheckedVoterRegistration],
  );
  const fullRecruitsColor = useMemo(
    () => isCheckedVoterRegistration && !!recruitsCounter,
    [recruitsCounter, isCheckedVoterRegistration],
  );

  if (!referralLink) return null;

  return (
    <Container>
      <Box>
        <Entries fullColor={fullEntriesColor} />
        <Counter $fullColor={statsLoading || fullEntriesColor}>
          {statsLoading ? <Spin /> : entriesCounter || 0}
        </Counter>
        <Text>{t('fan_out.main.earned', { count: entriesCounter || 0 })}</Text>
      </Box>
      <Box>
        <Recruits fullColor={fullRecruitsColor} />
        <Counter $fullColor={statsLoading || fullRecruitsColor}>
          {statsLoading ? <Spin /> : recruitsCounter || 0}
        </Counter>
        <Text>{t('fan_out.main.recruit', { count: recruitsCounter || 0 })}</Text>
      </Box>
    </Container>
  );
};

EntriesInfo.propTypes = {
  entriesCounter: PropTypes.number,
  isCheckedVoterRegistration: PropTypes.bool.isRequired,
  pendingCounter: PropTypes.number,
  recruitsCounter: PropTypes.number,
  referralLink: PropTypes.string,
  statsLoading: PropTypes.bool.isRequired,
};

EntriesInfo.defaultProps = {};

export default EntriesInfo;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FanOutLogo from '@web/components/FanOut/components/icons/FanOutLogo';
import VotingPlanUnderline from '@web/components/FanOut/components/icons/VotingPlan';
import { capitalize } from '@web/utils/string';
import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import theme from '@web/styles/theme';
import useIsMobile from '@web/components/VoterRegistration/hooks/useIsMobile';

const Wrapper = styled.div`
  display: flex;
  max-width: 500px;
  min-width: 370px;

  ${smallBreakpoint} {
    width: 100%;
    max-width: unset;
    min-width: unset;
  }
`;

const VotingPlan = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 16px;
  padding: 34px 28px;
  max-width: 500px;
  height: calc(100vh - 64px);
  width: 100%;
  border-radius: 0.75rem;
  border: 2px solid ${({ theme }) => theme.colors.blue};
  background: radial-gradient(
      64.5% 64.52% at 97.64% 1.3%,
      rgba(197, 55, 94, 0.2) 0%,
      rgba(197, 55, 94, 0) 100%
    ),
    radial-gradient(
      66.22% 90.3% at 98.11% 98.85%,
      rgba(140, 205, 217, 0.2) 0%,
      rgba(140, 205, 217, 0) 100%
    ),
    radial-gradient(
      64.49% 126.95% at 0% 100%,
      rgba(184, 59, 201, 0.2) 0%,
      rgba(184, 59, 201, 0) 100%
    ),
    radial-gradient(
      86.75% 134.97% at 0% 0%,
      rgba(152, 204, 201, 0.2) 0%,
      rgba(152, 204, 201, 0) 100%
    ),
    radial-gradient(
      53.76% 53.76% at 50% 46.24%,
      rgba(225, 159, 83, 0.2) 0%,
      rgba(225, 159, 83, 0) 100%
    ),
    #fff;
  box-shadow: 4px 4px 0px 0px #23293e;

  ${smallBreakpoint} {
    width: 100%;
    max-width: unset;
    min-width: unset;
    height: unset;
    padding: 20px;
  }
`;

const StyledFanOutLogo = styled(FanOutLogo)`
  width: 107px;
  height: 20px;
`;

const TitleWrapper = styled.div`
  margin-top: 76px;

  ${smallBreakpoint} {
    margin-top: 23px;
  }
`;

const TitleText = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 3rem;
  line-height: 4rem;
  position: relative;

  ${smallBreakpoint} {
    font-size: 1.75rem;
    line-height: 2.25rem; /* 128.571% */
  }
`;

const StyledVotingPlanUnderline = styled(VotingPlanUnderline)`
  position: absolute;
  bottom: -10px;
  left: -5px;
`;

const DescriptionText = styled.div`
  margin-top: 40px;
  color: ${({ theme }) => theme.colors.blackL32};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */

  span {
    display: block;
    margin-top: 1.5rem;

    ${smallBreakpoint} {
      margin-top: 0.5rem;
    }
  }

  ${smallBreakpoint} {
    margin-top: 20px;
    font-size: 0.875rem;
    line-height: 1.5rem; /* 171.429% */
  }
`;

const CreateVotingPlan = ({ referrer }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Wrapper>
      <VotingPlan>
        <StyledFanOutLogo fill={theme.colors.trueBlack} />
        <TitleWrapper>
          <TitleText>{t('fan_out.voting_plan.create_voting_plan.create')}</TitleText>
          <TitleText>
            {t('fan_out.voting_plan.create_voting_plan.voting_plan')}
            <StyledVotingPlanUnderline isMobile={isMobile} />
          </TitleText>
        </TitleWrapper>
        <DescriptionText>
          {t('fan_out.voting_plan.create_voting_plan.plan')}
          <span>
            {t('fan_out.voting_plan.create_voting_plan.creating', {
              referrer: capitalize(referrer?.fullname || ''),
            })}
          </span>
        </DescriptionText>
      </VotingPlan>
    </Wrapper>
  );
};

CreateVotingPlan.propTypes = {
  referrer: PropTypes.shape({
    fullname: PropTypes.string,
    id: PropTypes.number,
  }),
};

CreateVotingPlan.defaultProps = {};

export default CreateVotingPlan;

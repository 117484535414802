import axios from 'axios';

const route = '/api/v2/client/fan_out';

const fanOutRoutes = {
  getAllSweepstakes: async ({ phone, campaign_id, ...res }) => {
    return axios.get(`${route}/active_sweepstakes`, {
      params: { campaign_id, phone, ...res },
    });
  },

  getMyStats: async ({ phone, activity_id, campaign_id, ...res }) => {
    return axios.get(`${route}/my_stats`, {
      params: { activity_id, campaign_id, phone, ...res },
    });
  },

  getRecruits: async ({ phone, ...res }) => {
    return axios.get(`${route}/recruits`, {
      params: { phone, ...res },
    });
  },

  getVotingPlanReferralLink: async ({ campaign_id, phone, ...res }) => {
    return axios.get(`${route}/voting_plan_activity_referral_link`, {
      params: { campaign_id, phone, ...res },
    });
  },

  incrementPoints: async ({
    activity_id,
    campaign_id,
    contact_id,
    referral_hash,
    phone,
    email,
    ...res
  }) => {
    return axios.post(`${route}/increment_points`, {
      activity_id,
      campaign_id,
      contact_id,
      email,
      phone,
      referral_hash,
      ...res,
    });
  },

  sendAuthEmail: async ({
    phone,
    email,
    activity_slug,
    campaign_slug,
    activity_id,
    campaign_id,
    referral_link,
    registration_status,
    ...res
  }) => {
    return axios.post(`${route}/send_authentication_email`, {
      activity_id,
      activity_slug,
      campaign_id,
      campaign_slug,
      email,
      phone,
      referral_link,
      registration_status,
      ...res,
    });
  },

  validatePhoneAndEmail: async ({ phone, email, campaign_id, ...res }) => {
    return axios.get(`${route}/validate_phone_and_email`, {
      params: {
        campaign_id,
        email,
        phone,
        ...res,
      },
    });
  },

  validateVoterRegistration: async ({ phone, email, campaign_id, referral_hash, ...res }) => {
    return axios.get(`${route}/validate_voter_registration_submitted`, {
      params: {
        campaign_id,
        email,
        phone,
        referral_hash,
        ...res,
      },
    });
  },
};

export default fanOutRoutes;

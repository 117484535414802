import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CheckCircleFilled, CloseCircleFilled, QuestionCircleFilled } from '@ant-design/icons';
import { shape, bool, number, string, object } from 'prop-types';
import { Spin } from 'antd';
import useIsMobile from '@web/components/VoterRegistration/hooks/useIsMobile';

import { smallBreakpoint } from '@web/components/VoterRegistration/newComponents/breakpoints';
import { getInfo } from '@web/utils/getVoterInfo';
import Status from '@web/constants/contactRegistrationStatusTypes';
import Layout from '@web/components/VoterRegistration/newComponents/Layout';
import Modal from '@web/components/VoterRegistration/newComponents/Modal';
import VotingDetails from '@web/components/FanOut/components/VotingDetails';

const Wrapper = styled.div`
  display: flex;
  margin-top: 32px;
  width: 100%;
  padding: 16px 24px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
  flex-wrap: wrap;

  ${smallBreakpoint} {
    margin-top: 20px;
    flex-direction: column;
    gap: 6px;
    padding: 16px;
  }
`;

const BasicText = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
`;

const Title = styled(BasicText)`
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.5rem; /* 116.667% */

  ${smallBreakpoint} {
    font-size: 2rem;
    line-height: 2.25rem; /* 112.5% */
  }
`;

const StatusWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const StatusText = styled(BasicText)`
  font-weight: 700;
  line-height: 1.5rem; /* 150% */
  text-transform: uppercase;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 10px;
`;

const RegisteredIcon = styled(CheckCircleFilled)`
  &&& {
    font-size: 1.5rem;
    color: #52c41a;
  }
`;

const NotRegisteredIcon = styled(CloseCircleFilled)`
  &&& {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.red};
  }
`;

const UnknownIcon = styled(QuestionCircleFilled)`
  &&& {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.gray};
  }
`;

const VotingStatusContainer = styled.div``;

const VoterStatusText = styled(BasicText)`
  font-weight: 700;
  line-height: 1.5rem; /* 150% */
  text-transform: uppercase;
`;

const VotingDetailsLink = styled(BasicText)`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  line-height: 1.5rem; /* 150% */
  font-weight: 600;
  text-decoration-line: underline;

  cursor: pointer;

  :hover,
  :focus,
  .active {
    color: ${({ theme }) => theme.colors.black};
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.black};
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  gap: 16px;

  ${smallBreakpoint} {
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }
`;

const ContactText = styled(BasicText)`
  line-height: 1.25rem; /* 125% */
`;

const Spacer = styled.span`
  margin-right: 1rem;
`;

const StatusBox = ({ myStats, myStatsIsLoading, activity, team }) => {
  const { contactFormResult } = useSelector(state => state.voterRegistration);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const info = useMemo(() => getInfo(contactFormResult), [contactFormResult]);

  const { Icon, status } = useMemo(() => {
    if (myStats.status === Status.REGISTERED) {
      return { Icon: RegisteredIcon, status: t('voting_information.registered') };
    }
    if (myStats.status === Status.UNREGISTERED) {
      return { Icon: NotRegisteredIcon, status: t('voting_information.unregistered') };
    }
    return { Icon: UnknownIcon, status: t('voting_information.not_checked') };
  }, [myStats.status, t]);

  const showVotingDetailsButton = useMemo(
    () => myStats.contact?.voting_info && Object.keys(myStats.contact?.voting_info).length > 0,
    [myStats.contact?.voting_info],
  );

  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  return (
    <>
      <Title>{info.name}</Title>
      <Wrapper>
        <StatusWrapper>
          <StatusText>{t('fan_out.my_activity.my_status')}</StatusText>
          <StatusContainer>
            {myStatsIsLoading ? (
              <Spin />
            ) : (
              <>
                <Icon />
                <VotingStatusContainer>
                  <VoterStatusText>{status}</VoterStatusText>
                  {showVotingDetailsButton && (
                    <>
                      <VotingDetailsLink onClick={openModal}>
                        {t('fan_out.my_activity.show_voting_details')}
                      </VotingDetailsLink>
                      <Modal
                        modalOpen={isModalOpen}
                        closeModal={closeModal}
                        secondaryCloseButton={isMobile}
                        isFanOut
                      >
                        <Layout>
                          <VotingDetails
                            handleClose={closeModal}
                            contact={myStats.contact}
                            activity={activity}
                            team={team}
                          />
                        </Layout>
                      </Modal>
                    </>
                  )}
                </VotingStatusContainer>
              </>
            )}
          </StatusContainer>
        </StatusWrapper>
        <ContactText>
          <InfoWrapper>
            <span>
              {t('fan_out.my_activity.who')} {info.name}
              {info.age && `, ${info.age} ${t('fan_out.my_activity.old')}`}
            </span>
            {info.address && (
              <span>
                <Spacer>{t('fan_out.my_activity.at')}</Spacer> {info.address}
              </span>
            )}
          </InfoWrapper>
        </ContactText>
      </Wrapper>
    </>
  );
};

StatusBox.propTypes = {
  activity: object.isRequired,
  myStats: shape({
    contact: object,
    entries: number,
    recruits: number,
    status: string,
  }).isRequired,
  myStatsIsLoading: bool.isRequired,
  team: object,
};

StatusBox.defaultProps = {};

export default StatusBox;

import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '@web/components/DialerActivity/components/Button';

const StyledButton = styled(Button)`
  font-family: ${({ theme }) => theme.fonts.bold};
  letter-spacing: 0px;
  margin-bottom: 6px;
  ${({ disabled }) =>
    disabled &&
    `
      text-decoration: none;
    :hover {
      text-decoration: none;
    }
  `}
`;

function AnswerItem({ children, disabled, onPress, selected, variant, ...rest }) {
  function getButtonVariant() {
    if (disabled) {
      return 'outline';
    }
    if (selected) {
      return 'teal';
    }
    if (variant) {
      return variant;
    }
    return 'primary'; // darkBlue;
  }

  const buttonVariant = getButtonVariant();
  return (
    <StyledButton disabled={disabled} variant={buttonVariant} onClick={onPress} {...rest}>
      {children}
    </StyledButton>
  );
}

AnswerItem.propTypes = {
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool.isRequired,
  onPress: PropTypes.func,
  selected: PropTypes.bool,
  variant: PropTypes.string,
};

AnswerItem.defaultProps = {
  selected: false,
  variant: null,
};

export default AnswerItem;

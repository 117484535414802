import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import queryString from 'query-string';
import { flows } from '..';

const useRedirectRules = ({ activity, setSelectedFlow }) => {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const location = useLocation();
  const { redirect_campaign_slug, redirect_activity_slug, register_to_vote, redirect_team_slug } =
    queryString.parse(location.search);

  useEffect(() => {
    if (redirect_campaign_slug && redirect_activity_slug) {
      const teamPath = redirect_team_slug ? `/teams/${redirect_team_slug}` : '';

      setRedirectUrl(
        `/campaigns/${redirect_campaign_slug}/activities/${redirect_activity_slug}${teamPath}${
          register_to_vote ? `?register_to_vote=${register_to_vote}` : ''
        }`,
      );
    }
  }, [redirect_activity_slug, redirect_campaign_slug, redirect_team_slug, register_to_vote]);

  const goToCheckRegistration = useCallback(() => {
    if (!activity?.campaign?.headcount_rct_enabled && register_to_vote === 'true') {
      setSelectedFlow(flows.CHECK_REGISTRATION);
    }
  }, [activity?.campaign?.headcount_rct_enabled, register_to_vote, setSelectedFlow]);

  useEffect(() => {
    goToCheckRegistration();
  }, [goToCheckRegistration]);

  return redirectUrl;
};

export default useRedirectRules;
